<template>
  <div class="header-menu align-items-stretch">
    <div
      class="
        menu
        menu-rounded
        menu-column
        menu-lg-row
        menu-active-bg
        menu-state-primary
        menu-title-gray-700
        menu-arrow-gray-400
        fw-semibold
        my-5 my-lg-0
        px-2 px-lg-0
        align-items-stretch
      "
      id="#kt_header_menu"
      data-kt-menu="true"
    >
      <div
        class="
          menu-item
          here
          show
          menu-here-bg menu-lg-down-accordion
          me-0 me-lg-2
        "
      >
        <span class="menu-link py-3"
          ><span class="menu-title">{{ activeMenu.menu }}</span
          ><span class="menu-arrow d-lg-none"></span
        ></span>
        <div
          class="
            menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown
            p-0
            w-100 w-lg-850px
          "
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { watchEffect } from "@vue/runtime-core";
import useTemplate from "../../../modules/template/composables/useTemplate";
export default {
  setup() {
    const { activeMenu } = useTemplate();
    watchEffect(() => activeMenu.value);
    return { activeMenu };
  },
};
</script>

<style scoped>
.menu-title {
  text-transform: uppercase;
}
</style>