<template>
  <div class="toolbar py-1" id="kt_toolbar">
    <div
      id="kt_toolbar_container"
      class="container-fluid d-flex align-items-center"
    >
      <div class="d-flex align-items-center flex-wrap">
        <div class="flex-shrink-0 me-2">
          <ul class="nav">
            <li
              class="nav-item"
              v-for="submenu in activeMenu.submenus"
              :key="submenu.id"
            >
              <template v-if="submenu.submenu.length > 0">
                <a
                  class="
                    nav-link
                    btn
                    btn-sm
                    btn-color-muted
                    btn-active-color-primary
                    btn-active-light
                    fw-semibold
                    fs-7
                    px-4
                    me-1
                  "
                  :class="{
                    active: activeSubMenu && activeSubMenu.id === submenu.id,
                  }"
                  data-bs-toggle="tab"
                  @click="handleSubmenu(submenu)"
                  >{{ submenu.submenu }}</a
                >
              </template>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { watchEffect } from "vue";
import useTemplate from "../../modules/template/composables/useTemplate";
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();
    const { activeMenu, activeSubMenu, setActiveSubMenu } = useTemplate();
    const handleSubmenu = (submenu) => {
      setActiveSubMenu(submenu);
      return router.push({ name: submenu.modulo });
    };
    watchEffect(() => activeSubMenu.value);
    return { activeMenu, activeSubMenu, handleSubmenu };
  },
};
</script>

<style scoped>
</style>