import {computed} from "vue"
import {useStore} from "vuex"

const useAuth = () => {
  const store = useStore()

  const loginUser = async (user) => {
    return await store.dispatch("auth/loginUser", user)
  }

  const logoutUser = async () => {
    return await store.dispatch("auth/logoutUser")
  }

  const lostPassword = async (email) => {
    return await store.dispatch("auth/lostPassword", email)
  }

  const loginUserCode = async (user) => {
    return await store.dispatch("auth/loginUserCode", user)
  }

  return {
    nombre: computed(() => store.getters["auth/nombre"]),
    foto: computed(() => store.getters["auth/foto"]),
    loading: computed(() => store.getters["auth/loading"]),
    lostLoading: computed(() => store.getters["auth/lostLoading"]),
    codeLoading: computed(() => store.getters["auth/codeLoading"]),
    menus: computed(() => store.getters["auth/menus"]),
    loginUser,
    logoutUser,
    lostPassword,
    loginUserCode,
  }
}

export default useAuth
