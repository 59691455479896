<template>
  <div
    class="hover-scroll-overlay-y my-2 my-lg-5 pe-lg-n1"
    id="kt_aside_menu_wrapper"
  >
    <div
      class="
        menu
        menu-column
        menu-title-gray-700
        menu-state-title-primary
        menu-state-icon-primary
        menu-state-bullet-primary
        menu-arrow-gray-500
        fw-semibold
      "
      id="#kt_aside_menu"
      data-kt-menu="true"
    >
      <div
        class="menu-item py-2"
        :class="{ here: activeMenu.modulo == menu.modulo }"
        v-for="menu in menus"
        :key="menu.id"
        @click="handleMenu(menu.modulo)"
      >
        <span class="menu-link menu-center"
          ><span class="menu-icon me-0"
            ><i class="fs-1" :class="menu.icono"></i></span
          ><span class="menu-title">{{ menu.menu }}</span></span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import useAuth from "../../../modules/auth/composables/useAuth";
import useTemplate from "../../../modules/template/composables/useTemplate";
export default {
  setup() {
    const { menus } = useAuth();
    const { setActiveMenu, activeMenu, setActiveSubMenu } = useTemplate();
    const router = useRouter();
    const handleMenu = (modulo) => {
      const menuSelected = menus.value.find((item) => item.modulo === modulo);
      setActiveMenu(menuSelected);
      if (menuSelected.submenus.length > 0) {
        setActiveSubMenu(menuSelected.submenus[0]);
      }
      return router.push({ name: modulo });
    };
    return { menus, handleMenu, activeMenu };
  },
};
</script>

<style lang="scss" scoped>
</style>