<template>
  <Init />

  <div class="d-flex flex-column flex-root">
    <div class="page d-flex flex-row flex-column-fluid">
      <Base />
      <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
        <HBase />
        <Toolbar />
        <div
          class="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <Content />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Init from "../template/partials/theme-mode/Init.vue";
import Base from "../template/layout/aside/Base.vue";
import HBase from "./layout/header/HBase.vue";
import Toolbar from "./layout/Toolbar.vue";
import Content from "./layout/Content.vue";
export default {
  components: {
    Init,
    Base,
    HBase,
    Toolbar,
    Content,
  },
  setup() {
    return {};
  },
};
</script>

<style>
</style>