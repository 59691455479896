<template>
  <div id="kt_aside" class="aside overflow-visible pb-5 pt-5 pt-lg-0">
    <div class="aside-logo py-8" id="kt_aside_logo">
      <a href="?page=index" class="d-flex align-items-center">
        <img
          alt="Logo"
          src="/assets/media/logos/logo-circular.png"
          class="h-70px logo"
        />
      </a>
    </div>
    <div class="aside-menu flex-column-fluid" id="kt_aside_menu">
      <Menu />
    </div>
    <div class="aside-footer flex-column-auto" id="kt_aside_footer">
      <div class="d-flex justify-content-center">
        <button
          type="button"
          class="btn btm-sm btn-icon btn-custom btn-active-color-primary"
          title="Salir del sistema"
          @click="handleLogout"
        >
          <i class="fs-1 bi bi-power"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "../../layout/aside/Menu.vue";
import useAuth from "../../../modules/auth/composables/useAuth";
import { useRouter } from "vue-router";
export default {
  components: { Menu },
  setup() {
    const { logoutUser } = useAuth();
    const router = useRouter();

    const handleLogout = () => {
      logoutUser();
      return router.replace({ name: "login" });
    };
    return { handleLogout };
  },
};
</script>

<style lang="scss" scoped>
</style>