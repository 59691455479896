<template>
  <a
    @click="handleLogout"
    class="
      btn btn-icon btn-active-light-primary
      w-30px
      h-30px
      w-md-40px
      h-md-40px
    "
    title="Salir"
  >
    <i class="bi bi-power fs-2x"></i>
  </a>
</template>

<script>
import { useRouter } from "vue-router";
import useAuth from "../../../modules/auth/composables/useAuth";
export default {
  setup() {
    const { logoutUser } = useAuth();
    const router = useRouter();
    return {
      handleLogout: () => {
        logoutUser();
        return router.push({ name: "login" });
      },
    };
  },
};
</script>

<style lang="scss" scoped>
</style>