<template>
  <div
    id="kt_content_container"
    class="container-fluid min-h-550px container-xxl"
  >
    <router-view />
  </div>
</template>

<script>
export default {
  components: {},
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
</style>