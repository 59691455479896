<template>
  <div class="d-flex align-items-stretch flex-shrink-0">
    <div class="d-flex align-items-center ms-1 ms-lg-3">{{ nombre }}</div>
    <div class="d-flex align-items-center ms-1 ms-lg-3">
      <TMMain />
    </div>

    <div
      class="d-flex align-items-center ms-1 ms-lg-3"
      id="kt_header_user_menu_toggle"
    >
      <div class="cursor-pointer symbol symbol-30px symbol-md-40px">
        <img :src="foto" alt="image" />
      </div>
    </div>
  </div>
</template>

<script>
import TMMain from "../../partials/theme-mode/TMMain.vue";
import useAuth from "../../../modules/auth/composables/useAuth";
export default {
  components: {
    TMMain,
  },
  setup() {
    const { nombre, foto } = useAuth();
    return { nombre, foto };
  },
};
</script>

<style lang="scss" scoped>
</style>