<template>
  <div id="kt_header" style="" class="header align-items-stretch">
    <div
      class="container-fluid d-flex align-items-stretch justify-content-between"
    >
      <div
        class="d-flex align-items-center d-lg-none ms-n1 me-2"
        title="Show aside menu"
      >
        <div
          class="
            btn btn-icon btn-active-color-primary
            w-30px
            h-30px
            w-md-40px
            h-md-40px
          "
          id="kt_aside_mobile_toggle"
        >
          <span class="svg-icon svg-icon-1"
            ><svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                fill="currentColor"
              />
              <path
                opacity="0.3"
                d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                fill="currentColor"
              />
            </svg>
          </span>
        </div>
      </div>

      <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
        <a href="?page=index" class="d-lg-none">
          <img alt="Logo" src="assets/media/logos/demo6.svg" class="h-30px" />
        </a>
      </div>

      <div
        class="
          d-flex
          align-items-stretch
          justify-content-between
          flex-lg-grow-1
        "
      >
        <div class="d-flex align-items-stretch" id="kt_header_nav">
          <HMenu />
        </div>

        <TopBar />
      </div>
    </div>
  </div>
</template>

<script>
import HMenu from "./HMenu.vue";
import TopBar from "./TopBar.vue";
export default {
  components: { HMenu, TopBar },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
</style>